<template>
  <div class="banner">
    <div class="pt-4 d-flex mb-4">
      <h4 class="title">banner</h4>
      <el-button class="submit-button" type="primary" @click="$router.push('add')">新增</el-button>
    </div>
    <el-table
      row-key="id"
      :data="bannerInfos"
      style="width: 100%"
      :row-class-name="({row}) => 'filter-' + row.id"
      border>
      <el-table-column label="banner图" width="158">
        <template slot-scope="scope">
          <img class="w-100" v-if="scope.row.coverImage" :src="scope.row.coverImage" alt="banner图">
        </template>
      </el-table-column>
      <el-table-column label="内容摘要">
        <template slot-scope="scope">
          <div class="banner-content">
            <div style="flex:1;padding-left:7px;">
              <p class="title">{{scope.row.title}}</p>
              <p class="content">{{scope.row.abstract}}</p>
            </div>
          </div>
        </template>
      </el-table-column>
      <el-table-column label="操作" width="150" align="center">
        <template slot-scope="scope">
          <el-button class="btn-small" type="text" @click="$router.push('edit?id=' + scope.row.id + '&ptime=' + scope.row.publishedAt)">编辑</el-button>
          <el-button class="btn-small" type="text" @click="handleDelete(scope.row)">删除</el-button>
        </template>
      </el-table-column>
    </el-table>
  </div>
</template>

<script>
import Sortable from 'sortablejs'
import {
  guideList,
  guideDelete
} from '@/api/tips'
export default {
  name: 'banner',
  components: {
  },
  data () {
    return {
      bannerInfos: [],
      filterClass: ''
    }
  },
  created () {
  },
  mounted () {
    this.$nextTick(() => {
      this.init()
    })
  },
  methods: {
    init () {
      guideList().then(
        res => {
          this.bannerInfos = res.data.items
          this.filterClass = '.filter-' + this.bannerInfos[0].id
          console.log(this.filterClass)
          this.rowDrop()
        }
      )
    },
    async handleDelete (row) {
      try {
        await guideDelete(row.id)
        this.$notify({
          title: '成功',
          message: '删除成功',
          type: 'success'
        })
        this.init()
      } catch (err) {
        console.log(err)
      }
    },
    // 行拖拽
    rowDrop () {
      const tbody = document.querySelector('.el-table__body-wrapper tbody')
      const vm = this
      // let oldArr = []
      Sortable.create(tbody, {
        filter: vm.filterClass,
        dataIdAttr: 'class',
        // onMove (evt, originalEvent) {
        //   const newClass = vm.filterClass.slice(1)
        //   if (originalEvent.target.parentNode?._prevClass?.indexOf(newClass) !== -1) {
        //     return false
        //   }
        // },
        // onStart ({ newIndex, oldIndex }) {
        //   this.oldArr = this.toArray()
        //   // console.log('onStart', newIndex, oldIndex, this.toArray())
        // },
        onEnd ({ newIndex, oldIndex }) {
          if (newIndex === 0) {
            const currentRow = vm.bannerInfos.splice(oldIndex, 1)[0]
            vm.bannerInfos.splice(newIndex === 0 ? oldIndex : newIndex, 0, currentRow)
            const newList = vm.bannerInfos.slice(0)
            vm.bannerInfos = []
            vm.$nextTick(() => {
              vm.bannerInfos = newList
            })
          }
          console.log(newIndex, oldIndex)
        }
      })
    }
  }
}
</script>

<style lang="less" scoped>
.banner .title {
  font-weight: 700;
  font-size: 20px;
  flex: 1;
}
.banner-content {
  display: flex;
  .banner-img {
    width: 118px;
    height: 50px;
    flex-shrink: 0;
  }
  img {
    width: 118px;
    height: 50px;
    object-fit: cover;
  }
  .title {
    width: 538px;
    margin-bottom: 0;
    font-size: 14px;
    color: #555555;
    white-space: nowrap;
    overflow: hidden;
    text-overflow:ellipsis;
  }
  .content {
    width: 538px;
    margin-bottom: 0;
    color: #999999;
    white-space: nowrap;
    overflow: hidden;
    text-overflow:ellipsis;
  }
}
.banner .btn-small {
  padding: 0;
  &.el-button--text {
    font-size: 14px;
    color: #3775F6;
    &:focus {
      outline: none;
    }
  }
  &.danger {
    color: #F56C6C;
  }
  /deep/span:focus-visible {
    outline: none;
  }
}
.banner .btn-small.el-button+.el-button {
  margin-left: 3px;
}
.banner .text-btn {
  font-size: 14px;
  color: #3775F6;
}
.banner {
  /deep/.el-table--border td,
  /deep/.el-table--border th,
  /deep/.el-table__body-wrapper .el-table--border.is-scrolling-left~.el-table__fixed,
  /deep/.el-table td, .el-table th.is-leaf,
  /deep/.el-table--border, .el-table--group {
    border-color: #D9D9D9;
  }
  /deep/.el-table--border::after,
  /deep/.el-table--group::after,
  /deep/.el-table::before {
    background-color: #D9D9D9;
  }
  /deep/.el-table th>.cell {
    color: #333;
    text-align: center;
  }

}
</style>

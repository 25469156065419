import request from './index'

export function tipLists (type) {
  return request({
    url: '/api/tip/admin/list',
    method: 'get',
    params: {
      type
    }
  })
}

export function delTip (id) {
  return request({
    url: '/api/tip/delete',
    method: 'post',
    data: { id }
  })
}

export function curTipInfo (id) {
  return request({
    url: `/api/tips/${id}`,
    method: 'get'
  })
}

export function postTip (data) {
  return request({
    url: '/api/tip',
    method: 'post',
    data
  })
}

export function recruitsList (params = {}) {
  return request({
    url: '/api/recruits',
    method: 'get',
    params
  })
}

export function getRecruit (id) {
  return request({
    url: `/api/recruits/${id}`,
    method: 'get'
  })
}

export function updateRcrStatus (id, status) {
  return request({
    url: `/api/recruits/${id}/status`,
    method: 'post',
    data: {
      status
    }
  })
}

export function addRecruits (data = {}) {
  return request({
    url: '/api/recruits',
    method: 'post',
    data
  })
}

export function updateRecruits (id, data = {}) {
  return request({
    url: `/api/recruits/${id}`,
    method: 'post',
    data
  })
}
// gettoken
export function getToken () {
  return request({
    url: '/api/upload/token?path=image',
    method: 'get'
  })
}
// 帮助文档list
export function getHelpDocument () {
  return request({
    url: '/api/help/admin/list',
    method: 'get'
  })
}
// 删除一条帮助文档的内容
export function delHelpDocument (data) {
  return request({
    url: '/api/help/delete',
    method: 'post',
    data
  })
}
// 获取一条帮助文档的内容
export function getOneHelp (id) {
  return request({
    url: `/api/help?id=${id}`,
    method: 'get'
  })
}
// 保存一条帮助文档的内容
export function help (data) {
  return request({
    url: '/api/help',
    method: 'post',
    data
  })
}
// 发布一条帮助文档的内容
export function helpPublish (data) {
  return request({
    url: '/api/help/publish',
    method: 'post',
    data
  })
}
// 关于泛函list
export function getAboutFH () {
  return request({
    url: '/api/about/admin/list',
    method: 'get'
  })
}
// 删除一条关于泛函的内容
export function delAboutFN (data) {
  return request({
    url: '/api/about/delete',
    method: 'post',
    data
  })
}
// 获取一条关于泛函的内容
export function getOneAbout (id) {
  return request({
    url: `/api/about?id=${id}`,
    method: 'get'
  })
}
// 保存一条关于泛函的内容
export function about (data) {
  return request({
    url: '/api/about',
    method: 'post',
    data
  })
}
// 发布一条关于泛函的内容
export function aboutPublish (data) {
  return request({
    url: '/api/about/publish',
    method: 'post',
    data
  })
}

// 新手引导-管理员-新增或更新文章
export function guideEdit (data) {
  return request({
    url: '/api/guide',
    method: 'post',
    data
  })
}

// 新手引导-获取指定编号的文章
export function guideInfo (id) {
  return request({
    url: '/api/guide',
    method: 'get',
    params: { id }
  })
}

// 新手引导-管理员-查看列表
export function guideList (data) {
  return request({
    url: '/api/guide/admin/list',
    method: 'get'
  })
}

// 新手引导-管理员-发布文章
export function guidePublish (id) {
  return request({
    url: '/api/guide/publish',
    method: 'post',
    data: { id }
  })
}

// 新手引导-管理员-删除文章
export function guideDelete (id) {
  return request({
    url: '/api/guide/delete',
    method: 'post',
    data: { id }
  })
}
